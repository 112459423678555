
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import InvoiceLayout from '@/layouts/InvoiceLayout.vue';

export default defineComponent({
  components: {
    DefaultLayout,
    InvoiceLayout,
  },

  computed: {
    resolveLayout() {
      return this.$route.meta.layout || 'default-layout';
    },
  },
});
