
import { defineComponent } from 'vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import UiQrcode from '@/components/ui/UiQrcode.vue';
import UiCopyToClipboard from '@/components/ui/UiCopyToClipboard.vue';
import UiSkeletonBox from '@/components/ui/UiSkeletonBox.vue';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import { InvoiceAddresses } from '@/services/InvoicesService/types';

const __default__ = defineComponent({
  components: {
    UiQrcode,
    UiCopyToClipboard,
    UiSkeletonBox,
    UiButtonLoading,
  },

  data() {
    return {
      tabActive: 0,
      invoiceExpiredTimer: 0 as ReturnType<typeof setInterval>,
      invoiceUpdateTimer: 0 as ReturnType<typeof setTimeout>,
      showAddressBtnLoading: false,
      isInvoiceWatch: false,
    };
  },

  computed: {
    numberOfTabs(): number {
      return Object.keys(this.invoiceAddresses).length ?? 1;
    },

    invoiceId() {
      return this.$route.params.invoice_id;
    },

    ...mapState('invoices', {
      invoice: 'invoice',
      invoiceMsgStatus: 'invoiceMsgStatus',
      invoiceAddresses: 'invoiceAddresses',
      invoiceTimerData: 'invoiceTimerData',
      loading: 'loading',
    }),
  },

  watch: {
    invoice() {
      this.pollingInvoiceData();
      this.initializeTimer();
      this.setActiveTab();
      this.isInvoiceWatch = true;
    },
  },

  beforeMount() {
    this.loadInvoiceData(this.invoiceId);
    this.setLoading(true);
  },

  beforeUnmount() {
    clearInterval(this.invoiceExpiredTimer);
    clearTimeout(this.invoiceUpdateTimer);
    this.clearInvoiceData();
  },

  methods: {
    tabHandler(index: number) {
      this.tabActive = index;
    },

    pollingInvoiceData() {
      this.invoiceUpdateTimer = setTimeout(() => {
        if (
          this.invoice.status === 'waiting'
          || this.invoice.status === 'partially_paid'
          || this.invoice.status === 'waiting_confirmations'
        ) {
          clearTimeout(this.invoiceUpdateTimer);
          clearInterval(this.invoiceExpiredTimer);
          this.loadInvoice(this.invoiceId);
        } else {
          clearTimeout(this.invoiceUpdateTimer);
          clearInterval(this.invoiceExpiredTimer);
        }
      }, process.env.VUE_APP_INVOICE_POLLING_TIMEOUT * 1000);
    },

    updateTimer() {
      this.invoiceTimer();
      if (this.invoiceTimerData.total <= 0) {
        clearInterval(this.invoiceExpiredTimer);
      }
    },

    initializeTimer() {
      this.updateTimer();
      this.invoiceExpiredTimer = setInterval(this.updateTimer, 1000);
    },

    async loadInvoiceAddressHandler(currencyId: string) {
      try {
        this.showAddressBtnLoading = true;
        await this.loadInvoiceAddress({
          invoiceId: this.invoiceId,
          currencyId,
        });
        await this.loadInvoiceData(this.invoiceId);
        this.showAddressBtnLoading = false;
      } catch (e) {
        this.showAddressBtnLoading = false;
      }
    },

    setActiveTab() {
      if (!this.isInvoiceWatch) {
        this.tabActive = this.invoiceAddresses
          .reduce((acc: number, curr: InvoiceAddresses, i: number) => {
            if (curr.currency === this.invoice.paymentMethod) {
              return i;
            }
            return acc;
          }, 0);
      }
    },

    ...mapMutations('invoices', ['clearInvoiceData', 'setLoading']),
    ...mapActions('invoices', ['loadInvoice', 'loadInvoiceData', 'invoiceTimer', 'loadInvoiceAddress']),
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "f49bf018": (_ctx.numberOfTabs)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__