import { Plugin } from 'vue';
import store from '@/store';
import router from '@/router';

const initial: Plugin = async () => {
  await router.isReady();

  if (!store.state.dictionaries.isDictionariesLoading) {
    await store.dispatch('dictionaries/loadDictionaries');
  }
};

export default initial;
