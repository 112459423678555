import { RouteRecordRaw } from 'vue-router';
import { AppLayout } from '@/layouts/types';
import PageNotFoundView from '@/views/PageNotFoundView.vue';
import InvoiceView from "@/views/invoices/InvoiceView.vue";

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/invoices/:invoice_id',
    name: 'invoice',
    component: InvoiceView,
    meta: {
      layout: AppLayout.INVOICE,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: PageNotFoundView,
    meta: {
      layout: AppLayout.DEFAULT,
    },
  },
];
